export default {
  redemption: "รับ ",
  redemption_reward: "แลก ",
  collection: "รับ ",
  _items: "รายการ",
  hello: "สวัสดีคุณ,",
  _points: "Points",
  term_and_conditions_shop: " ข้อตกลง & เงื่อนไข",
  need_update_info: "คุณต้องอัปเดทข้อมลส่วนตัวของคุณ!",
  free_point: "Free point",
  accept: "ยอมรับ",
  _terms_and_conditions: " ข้อตกลง & เงื่อนไข",
  dont_have_an_account: "คุณยังไม่ได้เป็นสมาชิก? ",
  membership_details: "รายละเอียดสมาชิก ",
  sign_up_to: "สมัครสมาชิกร้าน ",
  sign_in_to: "ลงชื่อเข้าใช้ร้าน ",
  choose_sign_up_method: "เลือกช่องทางสมัครสมาชิก ",
  choose_sign_in_method: "เลือกช่องทางลงชื่อเข้าใช้งาน ",
  or: "หรือ",
  forgot: "ลืม",
  password: " รหัสผ่าน?",
  _password: "รหัสผ่าน",
  email: "อีเมล",
  tel: "เบอร์โทร",
  phone: "เบอร์โทร",
  name: "ชื่อ",
  remember_me: "จดจำฉัน",
  confirm_password: "ยืนยันรหัสผ่าน",
  reset_your_password: "รีเซ็ทรหัสผ่านของคุณ",
  email_recovery_link: "รับอีเมลรีเซ็ทรหัสผ่าน",
  new_password: "รหัสผ่านใหม่",
  confirm_new_password: "ยืนยันรหัสผ่านใหม่",
  sign_up: "สมัครสมาชิก",
  already_have_an_account: "คุณเป็นสมาชิกอยู่แล้ว? ",
  sign_in: "ลงชื่อเข้าใช้งาน",
  please_enter_OTP: "กรุณากรอก OTP",
  submit: "ยืนยัน",
  save: "บันทึก",
  verify_email: "ยืนยันอีเมล",
  verify_phone: "ยืนยันเบอร์โทร",
  resend_OTP: "ส่ง OTP อีกครั้ง",
  fill_in_the_information_and_receive_special_benefits: "กรอกข้อมูลเพื่อรับสิทธิสุดพิเศษ",
  birth_date: "วันเดือนปีเกิด (วว/ดด/ปป)",
  accept_terms_conditions: "ยอมรับ ข้อตกลง & เงื่อนไข",
  sign_up_failed: "สมัครสมาชิกไม่สำเร็จ",
  sign_in_failed: "ลงชื่อเข้าใช้งานไม่สำเร็จ",
  email_password_correct: "อีเมลและรหัสผ่านไม่ถูกต้อง",
  login_successfully: "ลงชื่อเข้าใช้งานสำเร็จ",
  login_failed: "ลงชื่อเข้าใช้งานไม่สำเร็จ",

  points: "แต้มสะสม",
  coupon: "คูปอง",
  coupon_label: "คูปอง",
  coupons: "คูปอง",
  expire_in: "หมดอายุภายใน",
  _month: "เดือน",
  use_point: "ใช้แต้ม",
  benefits: "สิทธิพิเศษ",
  history: "ประวัติ",

  collected: "เก็บ",
  discount_coupon: "Coupon ส่วนลด",
  desc: "ใช้ได้เมื่อซื้อสินค้ามากกว่า 100 บาท",
  expiration_date: "วันหมดอายุ",
  recceive_coupon: "รับ Coupon ",
  collect_coupon: "เก็บ coupon ",
  coupon_code: "โค้ด Coupon",
  use_coupon: "ใช้ coupon",
  BAHT: "บาท",
  percent_sign: "%",

  // coupon: {
  //     collected: "เก็บ",
  //     discount_coupon: "Coupon ส่วนลด",
  //     desc: "ใช้ได้เมื่อซื้อสินค้ามากกว่า 100 บาท",
  //     expiration_date: "วันหมดอายุ",
  //     recceive_coupon: "รับ Coupon ",
  //     collect_coupon: "เก็บ Coupon  ",
  //     coupon_code: "โค้ด Coupon",
  //     use_coupon: "ช้ coupon",
  // },
  quantity_available: "รายการนี้มี({{number}} จำนวนทั้งหมดเหลืออยู่)",
  have_points_left: "คุณจะเหลือ {{number}} Points",
  select_type: "เลือกประเภท",
  descriptions: "รายละเอียด",
  _point: "point",

  MEMBER: "สมาชิก {{text}}",
  use_points: "ใช้แต้ม",
  do_not_have_enough_points: "คุณมี Points ไม่เพียงพอ",
  description: "รายละเอียด",
  collect_benefit: "เก็บ Benefit",

  done: "เสร็จสิ้น",
  transaction: "รายการแลกเปลี่ยน",
  coupon_title: "Coupon ({{number}} ถูกส่งแล้ว)",

  used: "ถูกใช้งานแล้ว",

  point: "Point",
  earned: "ได้รับ",
  reward: "Reward",
  benefit: "Benefit",
  confirmation: "ยืนยัน",

  item: "รายการ",
  ticket: "เลขทำรายการ",
  date_time: "วันที่ / เวลา",
  confirm: "ยืนยัน",
  desConfirm: "กรุณาแสดงหน้าจอนี้หรือแคปเจอร์เก็บไว้ และ นำไปแสดงหลักฐานให้กับร้านค้า",

  welcome: "ยินดีต้อนรับ!",
  become_welcome: "มาเป็น ",
  desc_welcome: "สมาชิกเพื่อรับสิทธิสุดพิเศษ!",
  become: "ต้องการเป็นสมาชิก",
  qrCode: "QR Code",
  qrCode_thai: "ของฉัน",

  member_id: "เลขสมาชิก",

  request_connect_link: "ต้องการเชื่อมต่อบัญชีกับ LINE",
  link_line: "เชื่อมต่อบัญชี LINE",
  become_member: "ต้องการเป็นสมาชิก",
  fill_in_your_information: "กรุณากรอกข้อมูลของคุณเพื่อเป็นสมาชิก ",
  _and_receive: " เพื่อรับสิทธิสุดพิเศษดังนี้",
  star_desc: "สะสม Points เพื่อนำไปแลกของรางวัล",
  coupon_desc: "รับ Coupons",
  benefit_desc: "ฟรีสิทธิพิเศษสำหรับสมาชิก",
  draws_desc: "ร่วมลุ้นชิงโชค",
  rules_of_points: "กฎการสะสมคะแนน",
  _baht_spent: "บาท",

  phone_number: "เบอร์โทร",

  date: "วันที่",
  date_of_birth: "วันเดือนปีเกิด",
  link_different: "เชื่อมต่อบัญชี LINE",
  request_link: "ต้องการเชื่อมต่อบัญชีกับ LINE",
  _of: "จาก",
  _to_level: "ถึงขั้น {{rank}}",
  _level: "ขั้น",

  member_ShipTier: "Membership tier",

  member_shipTier_desc: "ปลดล็อคสิิทธิสุดพิเศษในแต่ละ Tier!",
  earn_points_in_a_year: "สะสม {{point}} Points ในหนึ่งปี",
  _member: "สมาชิก",
  _spent: " ยอดใช้งาน",

  point_given: "Point ที่ได้รับ",

  use_free_point: "ใช้ Free point",
  collect_free_points: "รับ free points",

  phone_desc: "เราได้ส่งโค้ด OTP ไปยังโทรศัพท์คุณ ",
  email_desc: "เราได้ส่งโค้ดยืนยันไปยังอีเมลคุณ",
  enter_it_here: "กรอกโค้ดของคุณที่นี่",
  resend_otp: "ส่ง OTP อีกครั้ง",
  resend_code: "ส่งโค้ดอีกครั้ง",

  goback_home: "Lavish",
  back: "ย้อนกลับ",
  select: "เลือก",
  increase: "เพิ่ม",
  decrease: "ลด",
  add_points_from_shop: "ได้รับ Points จากร้าน: {{points}} points ",
  sub_points_from_shop: "Points ถูกหักจากร้าน: {{points}} points",
  redeem_reward: "แลกรับ reward",
  redeem_benefit: "รับ benefit",
  please_sign_up_to_collect_free_point: "กรุณาสมัครสมาชิกเพื่อสะสม Free Proints",
  please_sign_up_to_collect_coupon: "กรุณาสมัครสมาชิกเพื่อสะสม Coupon",
  near_store: "ร้านค้าใกล้ฉัน",
  popular: "เป็นที่นิยม",
  all: "ทั้งหมด",
  summary_order: "สรุปยอดสั่งซื้อ",
  home: "หน้าหลัก",
  profile: "โปรไฟล์",
  details_menu: "รายละเอียดสินค้า",
  notation: "โน๊ต",
  add: "เพิ่มในตะกร้า",
  customer_name: "ชื่อลูกค้า",
  my_basket_orders: "ตะกร้าของฉัน",
  add_order: "เพิ่มสินค้า",
  edit_mode: "แก้ไขตะกร้า",
  basket: "ตะกร้า",
  promotions: "โปรโมชั่น",
  card: "การ์ด",
  edit_promotions: "แก้ไขโปรโมชั่น",
  apply_points: "ใช้แต้ม",
  apply_coupon: "ใช้คูปอง",
  apply_card: "ใช้การ์ด",
  payment: "ชำระเงิน",
  subtotal: "ยอดรวม",
  items: "ชิ้น",
  vat: "VAT",
  total: "Total",
  use_this_code: "ใช้โค้ดนี้",
  freebie: "Freebie",
  promotion: "โปรโมชั่น",
  apply_auto: "ใช้อัตโนมัติ",
  conflict: "มีข้อจำกัด",
  no_conflict: "ไม่มีข้อจำกัด",
  condition: "เงื่อนไข",
  result: "ผลลัพธ์",
  auto_apply: "ใช้โปรโมชั่นอัตโนมัติ",
  please_select_4_promotions: "โปรดเลือก 4 โปรโมชั่น",
  save_all_changes: "บันทึกทั้งหมด",
  discount: "ส่วนลด",
  cancel: "ยกเลิก",
  show_more: "Show more",
  calculate_point: "คำนวนแต้ม",
  your: "คุณมี",
  use_card: "ใช้การ์ด",
  confirm_payment: "ยืนยันการชำระเงิน",
  promotion_code: "รหัสโปรโมชั่น",
  apply_promotion_code: "กรอกรหัสโปรโมชั่น",
  amount_apply_card: "จำนวนเงินที่ต้องการใช้จากบัตร",
  select_freebie_add_on: "เลือกตัวเลือกของแถม",
  next: "ต่อไป",
  ex_a_little_sweet: "Ex. a little sweet",
  free: "Free",
  order_history: "ประวัติออเดอร์",
  my_order: "ออเดอร์ของฉัน",
  wait_queue: "คิวที่ต้องรอ",
  basket_order_summary: "สรุปรายการออเดอร์",
  order_id: "รหัสออเดอร์",
  your_queue_number: "คิวของคุณ",
  total_queue: "คิวทั้งหมด",
  points_discount: "ใช้แต้มลดราคา",
  promotion_discount: "ลดราคาจากโปรโมชั่น",
  details_customer: "รายละเอียดลูกค้า",
  payment_method: "วิธีชำระเงิน",
  service_charge: "ค่าบริการ",
  to_pay: "ยอดที่ต้องชำระ",
  pending: "กำลังดำเนินการ",
  complete: "เสร็จสิ้น",
  canceled: "ยกเลิก",
  member_card_usage_promotion: "โปรโมชั่น gift card",
  gift_card_usage_promotion: "โปรโมชั่น member card",
  gift_card_usage: "การใช้ gift card",
  gift_card_usage_promo: "โปรโมชั่น gift card",
  member_card_usage_promo: "โปรโมชั่น member card",
  load_more: "Load more",
  please_select_amount_to_use: "โปรดเลือกจำนวนที่จะใช้",
  disable_promotion: "โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นที่คุณเลือก",
};
