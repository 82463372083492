import { enumDrawerPlacement } from "@configs";
import { useTranslation } from "react-i18next";
import { SharedComponentDrawer } from "../shared/Drawer";
import styled from "styled-components";
import { IResultFreeItem } from "@interfaces";
import { selectMerchant, store } from "@redux";
import { Checkbox, Collapse, Skeleton, Tabs } from "antd";
import { organizationAPI } from "@api";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNotify } from "@utils";
import { IconClose } from "../icon/FreebieDrawer/IconClose";
import { posPromotionApi } from "src/API/posPromotion";
import { SharedInput, StyledSubmitButton } from "@components";
import { IconPromotionCode } from "../icon/BasketDrawer/IconPromotionCode";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

interface IProps {
  promotionsCodeDrawer: boolean;
  handleClose: () => void;
  setPromoCode?: any;
  setPartnerPromoCode?: any;
  setPromotionIdByPromoCode?: any;
}

export const ComponentPromotionsCodeDrawer = (props: IProps) => {
  const {
    promotionsCodeDrawer,
    handleClose,
    setPromoCode,
    setPartnerPromoCode,
    setPromotionIdByPromoCode,
  } = props;
  const { t } = useTranslation();
  const { error } = useNotify();
  const org = useSelector((state: any) => state.merchant);
  const cart = useSelector((state: any) => state.cart.cart);
  const promotionIDListRedux = useSelector((state: any) => state.cart.promotionIDList);
  const userInfo = store.getState().auth.userInfo;
  const dispatch = useDispatch();
  const { themeColor } = useSelector(selectMerchant);
  const { TabPane } = Tabs;
  const [tab, setTab] = useState<string>("0");
  const { Panel } = Collapse;
  const [listPartner, setListPartner] = useState<any>([]);
  const [selectPartnerDetail, setSelectPartnerDetail] = useState<any>({});
  const [listPromotion, setListPromotion] = useState<any>([]);
  const [selectPromotionCodeId, setSelectPromotionCodeId] = useState<string>("");
  const [txtInputPromoCode, setTxtInputPromoCode] = useState<string>("");
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    getPartnerByOrganizationId();
  }, []);

  useEffect(() => {
    getPromotionListByPromoPartner();
  }, [selectPartnerDetail]);

  const onChangePromotion = (key: string | string[]) => {
    if (key.length > 0) {
      setSelectPromotionCodeId(listPromotion[key[0]].pos_promotion_id);
    } else {
      setSelectPromotionCodeId("");
    }
    setTxtInputPromoCode("");
  };

  const generateResultPromotion = (items: any) => {
    let str = "Result: ";
    items.map((item: IResultFreeItem) => {
      if (item.name && item.free_qty) {
        str = str + `${item.name} (x${item.free_qty}) `;
      }
    });
    return str;
  };

  const getPartnerByOrganizationId = async () => {
    try {
      const response = await organizationAPI.getPartnerByOrganizationId({
        organization_id: org._id,
      });
      if (response.data.error === "0") {
        setListPartner(response.data.result);
      } else {
        error(response.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const getPromotionListByPromoPartner = async () => {
    setTxtInputPromoCode("");
    setSelectPromotionCodeId("");
    try {
      let params: any = {
        organization_id: org._id,
        store_id: org.store_id,
        profile_id: userInfo._id,
        xpage: 1,
        xlimit: 1000,
      };
      if (selectPartnerDetail && selectPartnerDetail._id) {
        params.partner_id = selectPartnerDetail._id;
      }
      const response = await posPromotionApi.getPromotionListByPromoPartner(params);
      if (response.data.error === "0") {
        setListPromotion(response.data.result);
      } else {
        error(response.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const onChangeTxtInputPromoCode = (value: any) => {
    setTxtInputPromoCode(value.target.value);
  };

  const onClickSelectPartner = (partner?: any) => {
    if (partner) {
      setSelectPartnerDetail(partner);
    } else {
      setSelectPartnerDetail({});
    }
  };

  const onClickApplyPromoCode = () => {
    setPromoCode(txtInputPromoCode);
    setPartnerPromoCode(selectPartnerDetail);
    setPromotionIdByPromoCode(selectPromotionCodeId);

    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  });

  console.log("loading", isLoading);

  const loadingsComponent = (
    <StyledSkeleton>
      <div className="filter-skeleton">
        <Skeleton
          loading={true}
          avatar
          active
          title={false}
          paragraph={{ rows: 2, width: [130, 130] }}
        ></Skeleton>
        <Skeleton
          loading={true}
          avatar
          active
          title={false}
          paragraph={{ rows: 2, width: [130, 130] }}
        ></Skeleton>
        <Skeleton
          loading={true}
          avatar
          active
          title={false}
          paragraph={{ rows: 2, width: [130, 130] }}
        ></Skeleton>
        <Skeleton
          loading={true}
          avatar
          active
          title={false}
          paragraph={{ rows: 2, width: [130, 130] }}
        ></Skeleton>
      </div>
    </StyledSkeleton>
  );

  return (
    <StyledPromotionsDrawer
      title={
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <IconPromotionCode />
              <p className="h3" style={{ margin: 0 }}>
                Promotion Code{" "}
              </p>
            </div>
            <div onClick={handleClose}>
              <IconClose />
            </div>
          </div>
        </>
      }
      placement={enumDrawerPlacement.BOTTOM}
      onClose={handleClose}
      visible={promotionsCodeDrawer}
      width={"100%"}
      closable={false}
      height={"85%"}
    >
      <StyledPromotionsBodyDrawer themeColor={themeColor}>
        <div className="layout-promotion">
          {/* <div className="title-section">
            {`${org.name} DC code`}
          </div>
          <div className="img-promotion" style={!selectPartnerDetail._id ? {border: '1px solid #946037', borderRadius: '8px'} : {border: '1px solid #D6D9DC', borderRadius: '8px'}} onClick={() => onClickSelectPartner()}>
            <img src={org.logoUrl} alt="" />
          </div> */}
          <div className="title-section">Partner DC code</div>
          <div className="layout-img-sq-promotion">
            <Skeleton
              loading={isLoading}
              active
              title={false}
              paragraph={{ rows: 4, width: [64, 64, 64, 64] }}
            >
              <Swiper slidesPerView={4.5} spaceBetween={10} pagination={true}>
                {listPartner &&
                  listPartner.map((partner: any, index: number) => (
                    <SwiperSlide key={index}>
                      <div
                        className="img-sq-promotion"
                        key={index}
                        style={
                          selectPartnerDetail?._id === partner._id
                            ? { border: "3px solid #946037", borderRadius: "8px" }
                            : { border: "1px solid #D6D9DC", borderRadius: "8px" }
                        }
                        onClick={() => onClickSelectPartner(partner)}
                      >
                        <img src={partner.partnerIcon || ""} alt="" />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Skeleton>
          </div>
          <div className="wrapper-result-promotion">
            {isLoading === false ? (
              <>
                <Collapse accordion>
                  {listPromotion &&
                    listPromotion.map((promotion: any, index: number) => (
                      <Panel
                        header={
                          <div className="result-promotion">
                            <div className="result-img">
                              <img src={promotion.pos_promotion_image_url} alt="" />
                            </div>
                            <div className="result-detail">
                              <h2>{promotion.pos_promotion_name || ""}</h2>
                            </div>
                            <div className="result-checklist">
                              <Checkbox defaultChecked={false} />
                            </div>
                          </div>
                        }
                        key={index}
                      >
                        <div className="collapse-result">
                          <SharedInput
                            placeholder="Enter Code Discount"
                            type="text"
                            value={txtInputPromoCode}
                            onChange={(value: any) => onChangeTxtInputPromoCode(value)}
                          />
                        </div>
                      </Panel>
                    ))}

                  {/* <Panel header={
                <div className="result-promotion">
                  <div className="result-img">
                    <img src="../images/img-detail-result-01.png" alt="" />
                  </div>
                  <div className="result-detail">
                    <h2>Rocket Discount<span>฿25.00</span></h2>
                    <h3>minimum  ฿<span>1,000.00</span></h3>
                  </div>
                  <div className="result-checklist">
                    <Checkbox defaultChecked={false} />
                  </div>
                </div>
              } key="1">
                <div className="collapse-result">
                  <SharedInput
                    placeholder="Enter Code Discount"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="result-promotion">
                  <div className="result-img">
                    <img src="../images/img-detail-result-01.png" alt="" />
                  </div>
                  <div className="result-detail">
                    <h2>Rocket Discount<span>฿25.00</span></h2>
                    <h3>minimum  ฿<span>1,000.00</span></h3>
                  </div>
                  <div className="result-checklist">
                    <Checkbox defaultChecked={false} />
                  </div>
                </div>
              } key="2">
                <div className="collapse-result">
                  <SharedInput
                    placeholder="Enter Code Discount"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </Panel>
              <Panel header={
                <div className="result-promotion">
                  <div className="result-img">
                    <img src="../images/img-detail-result-01.png" alt="" />
                  </div>
                  <div className="result-detail">
                    <h2>Rocket Discount<span>฿25.00</span></h2>
                    <h3>minimum  ฿<span>1,000.00</span></h3>
                  </div>
                  <div className="result-checklist">
                    <Checkbox defaultChecked={false} />
                  </div>
                </div>
              } key="3">
                <div className="collapse-result">
                  <SharedInput
                    placeholder="Enter Code Discount"
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </Panel> */}
                </Collapse>
              </>
            ) : (
              loadingsComponent
            )}
          </div>
        </div>
        <div>
          <StyledSubmitButton
            type="red"
            text={<span>{t("page.use_this_code")}</span>}
            className="subBtn"
            onClick={() => {
              onClickApplyPromoCode();
            }}
          />
        </div>
      </StyledPromotionsBodyDrawer>
    </StyledPromotionsDrawer>
  );
};

const StyledSkeleton = styled.div`
    .title-skeleton {
      position: relative;
      .ant-skeleton {
        .ant-skeleton-title {
          height: 14px;
          border-radius: 16px;
          width: 120px!important;
          margin: 0;
        }
      }
    }
    .filter-skeleton {
      .ant-skeleton {
        margin-bottom: 30px;
        .ant-skeleton-header {
          width: 42px!important;
          height: 42px;!important;
          border-radius:6px;
        }
        .ant-skeleton-content {
          .ant-skeleton-title {
            margin:0;
            margin-bottom: 5px;
            height: 14px;
            border-radius: 4px;
            width: 43px!important;
          }
          .ant-skeleton-paragraph {
            display:flex;
            margin: 0;
            margin-top: 0;
            gap: 10px;
            flex-direction: column;
            li {
              margin:0;
              margin-right: 10px;
              height: 14px;
              border-radius: 4px;
            }
          }
        }
      }
    }
    .content-skeleton {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      .ant-skeleton {
        display: flex;
        width: 100%;
        flex-direction: column;
        .ant-skeleton-header {
            padding-right: 0;
            span {
                width: 100%;
                height: 167px;
                border-radius: 14px;
            }
        }
        .ant-skeleton-title {
            width: 100%!important;
            height: 10px;
            border-radius: 4px;
        }
        .ant-skeleton-paragraph {
            margin-top:0!important;
            li {
                height: 10px;
                border-radius: 4px;
                &:last-child {
                    margin-top: 15px;
                }
            }
        }
      }
    }
`;

const StyledPromotionsDrawer = styled(SharedComponentDrawer)`
  .ant-drawer-body {
    padding-top: 0;
    background: #fff;
    padding: 0;
  }
  .ant-tabs-bar {
    border: none;
    margin: 0;
  }
  .ant-drawer-header {
    // padding-bottom: 0;
  }
  .ant-tabs-nav {
    margin: 0 20px 0 0;
    .ant-tabs-tab-active {
      color: #f22f46 !important;
    }
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding-left: 0;
    padding-right: 0;
    color: #919191;
    font-weight: 500;
  }
  .ant-tabs-ink-bar {
    background-color: #f22f46;
    height: 4px;
    border-radius: 10px;
  }
`;

const StyledPromotionsBodyDrawer = styled.div<{ themeColor: string }>`
  .layout-promotion {
    padding: 20px;
    height: 70dvh;
    overflow: scroll;
    margin-bottom: 12px;
    .title-section {
      position: relative;
      padding-left: 15px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 19px;
      color: #000;
      font-weight: 600;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background-color: ${(color) => color.themeColor};
        transform: translateY(-50%);
      }
    }
    .img-promotion {
      margin-bottom: 12px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .layout-img-sq-promotion {
      .ant-skeleton {
        .ant-skeleton-paragraph {
          display: flex;
          margin: 0;
          li {
            height: 64px;
            border-radius: 6px;
            margin: 0;
            margin-right: 15px;
          }
        }
      }
      margin-bottom: 30px;
      .img-sq-promotion {
        height: 50px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .wrapper-result-promotion {
    padding-top: 30px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      width: calc(100% + 40px);
      height: 10px;
      background-color: #fafafa;
    }
    .ant-collapse {
      border: none;
      background-color: #fff;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          padding: 0;
          .ant-collapse-arrow {
            display: none;
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
      .ant-collapse-content-box {
        padding: 0;
      }
      .ant-input {
        background-color: #fafafa;
      }
    }
    .result-promotion {
      position: relative;
      padding-left: 50px;
      margin-bottom: 20px;
      padding-right: 70px;
      .result-checklist {
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
        right: 5px;
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: ${(color) => color.themeColor};
          }
        }
        .ant-checkbox-inner {
          border-radius: 100%;
          border-color: ${(color) => color.themeColor};
        }
      }
      .result-img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 42px;
        height: 42px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .result-detail {
        h2 {
          font-size: 16px;
          line-height: normal;
          font-weight: 600;
          color: #000;
          span {
            margin-left: 5px;
            font-weight: 600;
          }
        }
        h3 {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: #919191;
        }
      }
    }
  }
  .btn-layout {
    position: relative !important;
    bottom: auto !important;
    width: 100%;
    button {
      margin: 0;
      width: 100%;
      height: 55px;
      background: ${(color) => color.themeColor};
      border-radius: 74px;
      padding: 0;
    }
  }
  input[type="radio"]:checked {
    background-color: red;
  }
  .ant-radio-inner::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(0.7);
    width: 50%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: transform 0.3s ease;
    background: transparent;
    border-radius: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    position: relative;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${(color) => color.themeColor};
    border: 2px solid ${(color) => color.themeColor};
    transition: background-color 0.3s ease;
  }
  .ant-radio {
    width: 17px;
    height: 17px;
  }
  span.ant-radio + * {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    color: #000000;
    display: flex;
    align-self: flex-start;
  }
  .ant-radio-checked::after,
  .ant-checkbox-checked::after {
    border: 1px solid ${(color) => color.themeColor};
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(color) => color.themeColor};
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(color) => color.themeColor};
  }
  .ant-checkbox-wrapper {
    margin-left: 0;
    line-height: 30px;
    display: flex;
    flex-direction: row;
  }
  .ant-checkbox + span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${(color) => color.themeColor};
    color: #fff;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid ${(color) => color.themeColor};
    z-index: 0;
  }
  .ant-radio-button-wrapper {
    height: auto;
    width: 115px;
    background: #f7f7f8;
    border-radius: 8px;
    border: 2px solid #f7f7f8;
    text-align: center;
    color: #b4b4b9;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 #f7f7f8;
  }
  .ant-radio-button-wrapper-checked span {
    margin-top: 2px;
  }
  .ant-radio-button-wrapper span {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  .ant-checkbox {
    top: 0.45em;
    height: fit-content;
  }
  .selectPromotion {
    margin: 0;
    color: #919191;
    padding: 8px 24px;
  }
  .promotionsCol {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .promotions {
    background: #fff;
    padding: 24px;
    .radioContent {
      text-align: left;
      margin-right: 10px;
    }
    .checkBoxCol {
      display: flex !important;
    }
    .radioImage {
      img {
        width: 70px;
        height: 100px;
        object-fit: cover;
      }
    }
  }
  .ant-radio-wrapper {
    margin-right: 0;
  }
  .subBtn {
    width: 100%;
    border-radius: 74px;
    background: ${(color) => color.themeColor};
    position: absolute;
    bottom: 20px;
    left: 0;
    margin: 0 20px;
    width: -webkit-fill-available;
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .swiper-pagination-bullets {
    bottom: 0;
  }
  .swiper-container {
    padding-bottom: 20px;
  }
`;
