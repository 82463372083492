import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { IconPin, IconCall, arrow } from "@components";
import {
  ICreateProductOrderPOSExample,
  IGetProductOrderOnTableByQrOpenTableExample,
  IGetStoreExample,
  IMerchant,
  IPaginationNext,
  IProduct,
} from "@interfaces";
import { PAGE_START, PAGINATION, PATH_HOME, PATH_ORDER_HISTORY } from "@configs";
import {
  clearCart,
  getInfo,
  selectAuth,
  selectMerchant,
  setAuth,
  setLoading,
  setMainColor,
  setMerchant,
  setPromotionIDList,
  store,
} from "@redux";
import { useEffect, useState } from "react";
import { storeAPI } from "src/API/store";
import { merchantApi, organizationAPI, posAPI, productAPI } from "@api";
import { AddOrderGrayButton } from "./icon";
import { AllStoresTab } from "./home/AllStoresTab";
import { SelectedStoreDetails } from "./home/SelectedStoreDetails";
import { ComponentProductDrawer } from "./home/ProductDrawer";
import { SummaryOrderBtn } from "./home/SummaryOrderBtn";
import { ComponentBasketDrawer } from "./home/BasketDrawer";
import { ComponentUsePointDrawer } from "./home/UsePointDrawer";
import { SharedButtonSub } from "./shared";
import { ComponentApplyCardDrawer } from "./home/ApplyCardDrawer";
import { useNotify } from "@utils";
import { ComponentCheckHostModal } from "./home/CheckHostModal";
import { ComponentPromotionsDrawer } from "./home/PromotionsDrawer";
import { ComponentPaymentDrawer } from "./home/PaymentDrawer";
import { TotalQueueIcon } from "./icon/TotalQueueIcon";
import { posPromotionApi } from "src/API/posPromotion";
import { ComponentProductSetAddonDrawer } from "./home/ProductSetAddonDrawer";
import { ComponentApplyCouponDrawer } from "./home/ApplyCouponDrawer";
import { Skeleton } from "antd";
import moment from "moment";

interface ICate {
  category: string;
  is_enable: boolean;
  _id: string;
}

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const ComponentHome = () => {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { token, urlMerchantId, urlStoreId } = useParams<{ token: string, urlMerchantId: string, urlStoreId: string }>();
  const { error, success } = useNotify();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [basketDrawer, setBasketDrawer] = useState<boolean>(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [ShowAllStores, setShowAllStores] = useState(false);
  const [ShowStoreDetails, setShowStoreDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const orgInfo = useSelector((state: any) => state.merchant);
  const userInfo = store.getState().auth.userInfo;
  const cart = useSelector((state: any) => state.cart.cart);
  const promotionIDList = useSelector((state: any) => state.cart.promotionIDList);
  const [orderList, setOrderList] = useState<IGetProductOrderOnTableByQrOpenTableExample>();
  const [allStoreDetails, setAllStoreDetails] = useState<any[]>([]);
  const [totalStores, setTotalStores] = useState<number>(0);
  const [cateList, setCateList] = useState<ICate[]>([]);
  const [usePointsDrawer, setUsePointsDrawer] = useState<boolean>(false);
  const [applyCardDrawer, setApplyCardDrawer] = useState<boolean>(false);
  const [applyCouponDrawer, setApplyCouponDrawer] = useState<boolean>(false);
  const [paymentDrawer, setPaymentDrawer] = useState<boolean>(false);
  const [productSetAddonDrawer, setProductSetAddonDrawer] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [storesPagination, setStoresPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [category_id, setCategoryID] = useState<string>("");
  const [paymentDetails, setPaymentDetails] = useState<ICreateProductOrderPOSExample>();
  const [pointsInput, setPointsInput] = useState<number>();
  const { themeColor } = useSelector(selectMerchant);
  const merchantId = useSelector(selectAuth)?.userInfo?.merchantId;
  const orgCode = useSelector(selectAuth)?.userInfo?.merchant?.subCode;
  const [checkHostModal, setCheckHostModal] = useState<boolean>(false);
  const [isHostClosed, setIsHostClosed] = useState<boolean>(false);
  const today = new Date();
  const dayOfWeek = today.toLocaleDateString(undefined, { weekday: "long" });
  const [isApplyPromotionAuto, setIsApplyPromotionAuto] = useState<boolean>(true);
  const [discountGiftCard, setDiscountGiftCard] = useState<string>("");
  const [discountGiftCardPromotion, setDiscountGiftCardPromotion] = useState<string>("");
  const [giftCardPromotionId, setGiftCardPromotionId] = useState<string>("");
  const [giftCardDetail, setGiftCardDetail] = useState<any>({});
  const [memberCardDetail, setMemberCardDetail] = useState<any>({});
  const [discountMemberCardPromotion, setDiscountMemberCardPromotion] = useState<string>("");
  const [memberCardPromotionId, setMemberCardPromotionId] = useState<string>("");
  const [totalQueueToday, setTotalQueueToday] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<string>("");
  const [partnerPromoCode, setPartnerPromoCode] = useState<any>({});
  const [promotionIdByPromoCode, setPromotionIdByPromoCode] = useState<string>("");
  const [productSet, setProductSet] = useState<any>([]);
  const [selectProductSet, setSelectProductSet] = useState<any>({});
  const [selectedFreebies, setSelectedFreebies] = useState<any[]>([]);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [isLoading, setIsloading] = useState(true);
  const currentDayName = moment().format('dddd').toLowerCase();

  const getUserInfo = async () => {
    await dispatch(
      setAuth({
        accessToken: token,
        merchantId: merchantId || "",
        refreshToken: null,
        userPermissions: [],
      })
    );
    await dispatch(getInfo());
  };

  useEffect(() => {
    if (merchantId && paymentDetails && cart.length !== 0) {
      getPaymentDetails();
    }
  }, [promotionIDList]);

  useEffect(() => {
    if (token) {
      getUserInfo();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getMerchantAndStore();
    }
  }, [token, userInfo]);

  const getMerchantAndStore = async () => {
    if (token && userInfo) {
      if (merchantId) {
        const merchantConfig = await merchantApi.getInitMerchant(merchantId);
        const merchant = merchantConfig.data.merchant as IMerchant;
        // dispatch(setMerchant(merchant));
        if (urlMerchantId && urlStoreId && urlStoreId !== '') {
          const response = await storeAPI.getStoreById({organization_id: merchantId, store_id: urlStoreId});
          if (response.data.error === "0") {
            const storeDetail = response.data.result;
            dispatch(
              setMerchant({
                ...merchant,
                store_id: storeDetail._id,
                store_code: storeDetail.store_code,
                address: storeDetail.address,
                store_name: storeDetail.store_name,
                location: storeDetail.location,
                business_hours: storeDetail.business_hours,
              })
            );
          }
          history.push(PATH_HOME);
        } else {
          dispatch(setMerchant(merchant));
        }
      }
    }
  };
  
  useEffect(() => {
    if (token && userInfo && storesPagination === INITAL_PAGE) {
      loadAllStores();
    }
  }, [storesPagination]);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  });

  useEffect(() => {
    dispatch(setPromotionIDList([]));
    if (orgInfo) {
      if (orgInfo.store_id) {
        getProducts();
        getVariantInEachPosPromotionItemTypeVariant();
        getTotalQueue();
        loadCate();
        checkSaleShift();
        // setIsloading(false);
      }
      if (orgInfo._id) {
        loadAllStores();
        setShowAllStores(orgInfo.store_id ? false : true);
      }
    }
  }, [orgInfo]);

  useEffect(() => {
    if (paymentDetails && cart.length !== 0) {
      getPaymentDetails();
    }
  }, [
    giftCardDetail,
    discountGiftCard,
    discountGiftCardPromotion,
    discountMemberCardPromotion,
    promoCode,
  ]);

  const getProduct = async (item: string) => {
    try {
      const get = await productAPI.getProductByStoreId({
        organization_id: orgInfo._id,
        product_id: item,
        store_id: orgInfo.store_id,
      });
      if (get.data.error === "0") {
        setSelectedProduct(get.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkSaleShift = async () => {
    try {
      const check = await posAPI.checkStoreReadyForOnlineOrder({
        organization_id: orgInfo._id,
        store_id: orgInfo.store_id,
      });
      if (check.data.error === "1") {
        setCheckHostModal(true);
        setIsHostClosed(true);
      } else {
        setIsHostClosed(false);
      }
    } catch (err: any) {
      error(err.message);
      console.log(err.message);
    }
  };

  const loadAllStores = async () => {
    try {
      if (!hasMoreData) {
        return;
      }      
      const res = await storeAPI.getStores({
        organization_code: orgCode || orgInfo.subCode,
        // xlimit: storesPagination.xlimit,
        xlimit: 50,     //fix for show all store but limit 50
        xpage: storesPagination.xpage,
      });
      const newStores = res.data.result;
      if (newStores.length > 0 && storesPagination.xpage === 1) {
        setAllStoreDetails(res.data.result);
        setTotalStores(res.data.total);
      } else {
        setAllStoreDetails([...allStoreDetails, ...newStores]);
      }
      // if (newStores.length === 0 || newStores.length < storesPagination.xlimit) {
      if (newStores.length === 0 || newStores.length < 50) {    //fix for show all store but limit 50
        setHasMoreData(false);
      }
      if (orgInfo.store_id === "" || !orgInfo.store_id) {
        if (res.data.result.length > 0) {
          dispatch(
            setMerchant({
              ...orgInfo,
              _id: res.data.result[0].organization_id,
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadCate = async () => {
    const res = await organizationAPI.getProductCategorys({
      organization_code: orgCode || orgInfo.subCode,
      store_id: orgInfo.store_id,
    });
    if (res.data.error === "0") {
      setCateList(res.data.result);
    }
  };
  const getTotalQueue = async () => {
    try {
      const get = await productAPI.getTotalQueueTodayInStoreId({
        organization_id: orgInfo._id,
        store_id: orgInfo.store_id,
      });
      if (get.data.error === "0") {
        setTotalQueueToday(get.data.result);
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  const getProducts = async (category_id?: string, page?: number) => {
    try {
      const res = await productAPI.getProductsByPOS({
        organization_code: orgCode || orgInfo.subCode,
        category_id: category_id || "",
        text_search: "",
        xlimit: PAGINATION,
        xpage: page || PAGE_START,
        store_code: orgInfo.store_code,
      });
      if (res.data.error === "0") {
        const newProducts = res.data.result;
        if (newProducts.length > 0) {
          setProducts((prevProducts) => (!page ? newProducts : [...prevProducts, ...newProducts]));
          if (page) {
            if (page && page * PAGINATION >= res.data.total) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            if (PAGINATION >= res.data.total) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          }
        } else {
          setProducts([]);
          setHasMore(false);
        }
      }
    } catch (err) {
      console.log(err, "\nSomething went wrong, try again!");
    }
  };

  const getVariantInEachPosPromotionItemTypeVariant = async () => {
    try {
      const params = {
        organization_id: orgInfo._id,
        store_id: orgInfo.store_id,
        xpage: "1",
        xlimit: "1000",
      };
      const response = await posPromotionApi.getVariantInEachPosPromotionItemTypeVariant(params);
      if (response.data.error === "0") {
        setProductSet(response.data.result);
      } else {
        error(response.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const createProductOrderPOS = async (refRandom?: string) => {
    try {
      console.log(paymentDetails);
      let params: any = {
        organization_code: orgCode || orgInfo.subCode,
        product_order_id: "",
        client_id: userInfo._id,
        note: "POS order",
        store_id: orgInfo.store_id,
        table_id: "",
        employee_id: "",
        spend_point_discount: paymentDetails ? paymentDetails?.used_points : 0,
        discount_regular: 0,
        is_paysolution: false,
        is_client_order: true,
        pos_promotion_id_list: isApplyPromotionAuto ? [] : promotionIDList.length > 0 ? promotionIDList : null,
        order_list: cart.concat(selectedFreebies),
        api_type: "do",
        sub_payment_type: "qr",
        cash_receive: 0,
        bill_employee_id: "",
        sale_channel_id: "",
        ref_random: refRandom || '',
      };
      if (giftCardDetail && giftCardDetail._id) {
        params.loyalty_card_id = giftCardDetail._id;
        params.discount_in_baht_by_loyalty_card = discountGiftCard;
        params.discount_in_baht_promo_by_loyalty_card = discountGiftCardPromotion;
        params.loyalty_card_promotion_id = (giftCardPromotionId && giftCardPromotionId !== "") ? giftCardPromotionId : null;
      }
      if (memberCardDetail && memberCardDetail._id) {
        params.sub_payment_type = "member_card";
        params.cash_receive = 0;
        params.loyalty_member_card_id = memberCardDetail._id;
        params.discount_in_baht_promo_by_loyalty_member_card = discountMemberCardPromotion;
        params.loyalty_member_card_promotion_id = (memberCardPromotionId && memberCardPromotionId !== "") ? memberCardPromotionId : null;
      }
      if (promoCode && promoCode !== "") {
        params.promo_code_list = promoCode;
        if (promotionIDList.length > 0) {
          const newPromotionIdList = [...promotionIDList, promotionIdByPromoCode];
          params.pos_promotion_id_list = newPromotionIdList;
        }
      }
      if (partnerPromoCode && partnerPromoCode._id) {
        params.partner_id = partnerPromoCode._id;
      }
      const get = await productAPI.createProductOrderPOS(params);
      if (get.data.error === "0") {
        setPaymentDetails(get.data.result);
        handlePaymentClose();
        dispatch(clearCart());
        success("Payment Successful");
        history.push(PATH_ORDER_HISTORY);
      } else {
        error(get.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const createProductOrderPOSByPaySolution = async (refRandom?: string) => {
    try {
      console.log(paymentDetails);
      let params: any = {
        organization_code: orgCode || orgInfo.subCode,
        product_order_id: "",
        client_id: userInfo._id,
        note: "POS order",
        store_id: orgInfo.store_id,
        table_id: "",
        employee_id: "",
        spend_point_discount: paymentDetails ? paymentDetails?.used_points : 0,
        discount_regular: 0,
        is_paysolution: true,
        is_client_order: true,
        pos_promotion_id_list: isApplyPromotionAuto ? [] : promotionIDList.length > 0 ? promotionIDList : null,
        order_list: cart.concat(selectedFreebies),
        api_type: "do",
        sub_payment_type: "qr",
        cash_receive: 0,
        bill_employee_id: "",
        sale_channel_id: "",
        ref_random: refRandom || '',
        return_url: `${window.location.origin}/order-history`,     // return url callback
      };
      if (giftCardDetail && giftCardDetail._id) {
        params.loyalty_card_id = giftCardDetail._id;
        params.discount_in_baht_by_loyalty_card = discountGiftCard;
        params.discount_in_baht_promo_by_loyalty_card = discountGiftCardPromotion;
        params.loyalty_card_promotion_id = (giftCardPromotionId && giftCardPromotionId !== "") ? giftCardPromotionId : null;
      }
      if (memberCardDetail && memberCardDetail._id) {
        params.sub_payment_type = "member_card";
        params.cash_receive = 0;
        params.loyalty_member_card_id = memberCardDetail._id;
        params.discount_in_baht_promo_by_loyalty_member_card = discountMemberCardPromotion;
        params.loyalty_member_card_promotion_id = (memberCardPromotionId && memberCardPromotionId !== "") ? memberCardPromotionId : null;
      }
      if (promoCode && promoCode !== "") {
        params.promo_code_list = promoCode;
        if (promotionIDList.length > 0) {
          const newPromotionIdList = [...promotionIDList, promotionIdByPromoCode];
          params.pos_promotion_id_list = newPromotionIdList;
        }
      }
      if (partnerPromoCode && partnerPromoCode._id) {
        params.partner_id = partnerPromoCode._id;
      }
      const get = await productAPI.createProductOrderPOS(params);
      if (get.data.error === "0") {
        setPaymentDetails(get.data.result);
        console.log(get.data.result.payment_detail.submit_form_paysolution.paysolution_link);
        const path = get.data.result.payment_detail.submit_form_paysolution.paysolution_link || '';
        handlePaymentClose();
        dispatch(clearCart());
        // success("Payment Successful");
        history.push(PATH_HOME);
        if (path) {
          window.open(path, "_self");
        }
      } else {
        error(get.data.msg);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const getPaymentDetails = async (point?: number) => {
    try {
      let params: any = {
        organization_code: orgCode || orgInfo.subCode,
        product_order_id: "",
        client_id: userInfo._id,
        note: "POS order",
        store_id: orgInfo.store_id,
        table_id: "",
        employee_id: "",
        spend_point_discount: point ? point : pointsInput ? pointsInput : 0,
        discount_regular: 0,
        is_paysolution: false,
        is_client_order: true,
        pos_promotion_id_list: isApplyPromotionAuto ? [] : promotionIDList.length > 0 ? promotionIDList : null,
        order_list: cart.concat(selectedFreebies),
        api_type: "pre",
        sub_payment_type: "qr",
        cash_receive: 0,
        bill_employee_id: "",
        sale_channel_id: "",
      };
      if (giftCardDetail && giftCardDetail._id) {
        params.loyalty_card_id = giftCardDetail._id;
        params.discount_in_baht_by_loyalty_card = discountGiftCard;
        params.discount_in_baht_promo_by_loyalty_card = discountGiftCardPromotion;
        params.loyalty_card_promotion_id = (giftCardPromotionId && giftCardPromotionId !== "") ? giftCardPromotionId : null;
      }
      if (memberCardDetail && memberCardDetail._id) {
        params.sub_payment_type = "member_card";
        params.cash_receive = 0;
        params.loyalty_member_card_id = memberCardDetail._id;
        params.discount_in_baht_promo_by_loyalty_member_card = discountMemberCardPromotion;
        params.loyalty_member_card_promotion_id = (memberCardPromotionId && memberCardPromotionId !== "") ? memberCardPromotionId : null;
      }
      if (promoCode && promoCode !== "") {
        params.promo_code_list = promoCode;
        if (promotionIDList.length > 0) {
          const newPromotionIdList = promotionIdByPromoCode && promotionIdByPromoCode !== "" ? [...promotionIDList, promotionIdByPromoCode] : promotionIDList;
          params.pos_promotion_id_list = newPromotionIdList;
        }
      }
      if (partnerPromoCode && partnerPromoCode._id) {
        params.partner_id = partnerPromoCode._id;
      }
      const get = await productAPI.createProductOrderPOS(params);
      if (get.data.error === "0") {
        setPaymentDetails(get.data.result);
      } else {
        error(get.data.msg);
        const reGet = await productAPI.createProductOrderPOS({
          ...params,
          spend_point_discount: 0,
        });
        setPaymentDetails(reGet.data.result);
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  const handleCategoryChange = (category_id: string) => {
    setCategoryID(category_id);
    setPagination(INITAL_PAGE);
    getProducts(category_id);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleBasketDrawerClose = () => {
    setBasketDrawer(false);
    dispatch(setPromotionIDList([]));
    setDiscountMemberCardPromotion("0");
    setMemberCardDetail({});
    setSelectedFreebies([]);
    setDiscountGiftCard("");
    setDiscountGiftCardPromotion("");
    setGiftCardPromotionId("");
    setMemberCardPromotionId("");
    setGiftCardDetail({});
    setPointsInput(0);
  };

  const handleUsePointsClose = () => {
    setUsePointsDrawer(false);
  };
  const handleApplyCardClose = () => {
    setApplyCardDrawer(false);
  };
  const handleApplyCouponClose = () => {
    setApplyCouponDrawer(false);
  };
  const handlePaymentClose = () => {
    setPaymentDrawer(false);
    handleBasketDrawerClose();
  };
  const handleProductSetAddonClose = () => {
    setProductSetAddonDrawer(false);
  };
  const fetchMoreData = () => {
    const nextPage = pagination.xpage + 1;
    setPagination((prevPagination) => ({ ...prevPagination, xpage: nextPage }));
    getProducts(category_id, nextPage);
  };

  const handleScroll = () => {
      setStoresPagination({ ...storesPagination, xpage: storesPagination.xpage + 1 });
  };

  const loadingComponent = (
    <StyledSkeleton>
      <div className="title-skeleton">
        <Skeleton loading={true} active title={true} paragraph={false}></Skeleton>
      </div>
      <div className="content-skeleton">
        <Skeleton loading={true} avatar active title={true} paragraph={{ rows: 4 }}></Skeleton>
        <Skeleton loading={true} active avatar title={true} paragraph={{ rows: 4 }}></Skeleton>
      </div>
    </StyledSkeleton>
  );

  const loadingsComponent = (
    <StyledSkeleton>
      <Skeleton loading={true} avatar active title={false} paragraph={{ rows: 4 }}></Skeleton>
      <Skeleton loading={true} active avatar title={false} paragraph={{ rows: 4 }}></Skeleton>
      <Skeleton loading={true} active avatar title={false} paragraph={{ rows: 4 }}></Skeleton>
      <Skeleton loading={true} active avatar title={false} paragraph={{ rows: 4 }}></Skeleton>
    </StyledSkeleton>
  );

  // const currentDayIndex = new Date().getDay();
  // const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  // const currentDay = daysOfWeek[currentDayIndex];

  // // Filter today's business hours
  // const todaysHours = orgInfo.business_hours[currentDay];

  return (
    <>
      {!ShowStoreDetails ? (
        <>
          {!ShowAllStores ? (
            <>
              <div className="SalonName preview fixBorderRadius">
                <div className="topSalonName">
                  <h1
                    onClick={() => {
                      setShowAllStores(true);
                      setStoresPagination(INITAL_PAGE);
                    }}
                  >
                    {orgInfo.store_name || "Unknown"} <Styledarrow />
                  </h1>
                  <div className="topStoreInfo">
                    <div
                      onClick={() => {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${orgInfo.location.latlon[1]},${orgInfo.location.latlon[0]}`
                        );
                      }}
                    >
                      <StyledIconPin />
                    </div>
                    <div
                      onClick={() => {
                        window.location.href = "tel:" + orgInfo.businessTel;
                      }}
                    >
                      <StyledIconCall />
                    </div>
                  </div>
                </div>
                <div className="botSalonName">
                  <p>
                    <span>เวลาเปิดทำการ:</span>{" "}
                    <Skeleton loading={isLoading} active title={true} paragraph={false}>
                      {currentDayName && orgInfo.business_hours && orgInfo.business_hours[currentDayName] 
                      && orgInfo.business_hours[currentDayName].open_hour && orgInfo.business_hours[currentDayName].close_hour ? 
                      `${orgInfo.business_hours[currentDayName].open_hour} - ${orgInfo.business_hours[currentDayName].close_hour}` : ''}
                    </Skeleton>
                  </p>
                  {totalQueueToday !== -1 &&
                    <div className="totalQueueBadge">
                      <div className="totalQueueIcon">
                        <TotalQueueIcon />
                      </div>
                      <span className="body3">คิวขณะนี้ :</span>
                      <span className="h3">{totalQueueToday}</span>
                    </div>
                  }
                </div>
              </div>

              {isLoading === false ? (
                <>
                  {productSet.length !== 0 && (
                    <>
                      <div className="popularSection">
                        <h1 className="secondH1">{t("page.popular")}</h1>
                      </div>
                      <div className="swiperSection">
                        <Swiper
                          slidesPerView={2.5}
                          spaceBetween={20}
                          grabCursor={true}
                          className="mySwiper"
                        >
                          {productSet
                            ? productSet.map((item: any, i: number) => (
                                <SwiperSlide
                                  onClick={() => {
                                    setSelectProductSet(item.variant_list);
                                    setProductSetAddonDrawer(true);
                                  }}
                                  key={i}
                                >
                                  <img
                                    src={
                                      item.pos_promotion_image_url || "/images/default_service.png"
                                    }
                                  />
                                  <div className="ServiceTitle">
                                    <h1 
                                      className="topServiceTitle fix2NumOfLine"
                                    >
                                      {item.pos_promotion_name}
                                    </h1>
                                  </div>
                                  {item.promotion_type && item.promotion_type !== "freebie" ? (
                                    <div className="priceAndArrow">
                                      <h2
                                        style={{
                                          fontSize: 12,
                                          color: "#838795",
                                          textDecorationLine: "line-through",
                                          textDecorationStyle: "solid",
                                        }}
                                      >
                                        {item.full_price}{" "}
                                      </h2>
                                    </div>
                                  ) : null}
                                  <div className="priceAndArrow">
                                    <h2>
                                      {item.new_price}{" "}
                                      <span style={{ fontWeight: "normal" }}>฿</span>
                                    </h2>
                                    <AddOrderGrayButton />
                                  </div>
                                </SwiperSlide>
                              ))
                            : null}
                        </Swiper>
                      </div>
                    </>
                  )}
                </>
              ) : (
                loadingComponent
              )}
              <div className="servicesSection">
                <div className="leftSide">
                  <ul className="nav nav-tabs leftNavs" id="myTab" role="tablist">
                    <li className="nav-item noTopPadding">
                      <Skeleton loading={isLoading} active title={true} paragraph={false}>
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          onClick={() => {
                            handleCategoryChange("");
                          }}
                        >
                          {t("page.all")}
                        </a>
                      </Skeleton>
                    </li>
                    {isLoading === false ? (
                      <>
                        {cateList?.map((item: ICate, i: number) => (
                          <li className="nav-item" key={i}>
                            <a
                              className="nav-link"
                              id="cate-tab"
                              data-toggle="tab"
                              href="#cate"
                              role="tab"
                              aria-controls="cate"
                              aria-selected="false"
                              onClick={() => {
                                handleCategoryChange(item._id);
                              }}
                            >
                              {item.category}
                            </a>
                          </li>
                        ))}
                      </>
                    ) : null}
                  </ul>
                </div>
                <div className="rightSide" style={{ paddingBottom: cart.length !== 0 ? 180 : 80 }}>
                  {isLoading === false ? (
                    <>
                      {products.length > 0 &&
                        products.map((item, i: number) => (
                          <div
                            className="allServices"
                            onClick={() => {
                              // active stock
                              if (item.variant_list.every((variant: any) => variant.store.is_enable === false)) {
                                return;
                              }
                              setShowModal(true);
                              getProduct(item._id);
                            }}
                            key={i}
                            // active stock
                            style={item.variant_list.every((variant: any) => variant.store.is_enable === false) ? {position: 'relative'} : {}}
                          >
                            {/* active stock */}
                            {item.variant_list.every((variant: any) => variant.store.is_enable === false) &&
                              <div className="divProductNotAvailable">
                                <p className="textProductNotAvailable">
                                  Not available
                                </p>
                              </div>
                            }
                            <div className="coverImage">
                              <img src={item.image_url_list[0] || "/images/default_service.png"} />
                            </div>
                            <div className="serviceInfo">
                              <h1>{item.name}</h1>
                              <p>{item["description"].replaceAll(/<\/?[^>]+(>|$)/gi, "")}</p>
                              <div className="priceAndArrow">
                                <h2>{item.standard_price} ฿</h2>
                                <AddOrderGrayButton />
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    loadingsComponent
                  )}

                  {hasMore && (
                    <div className="loadMoreButton">
                      <SharedButtonSub
                        text={t("page.load_more")}
                        onClick={fetchMoreData}
                        type={"default"}
                        style={{ background: themeColor }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <AllStoresTab
              allStoreDetails={allStoreDetails}
              setShowAllStores={setShowAllStores}
              isHostClosed={isHostClosed}
              fetchMoreData={() => {
                handleScroll();
              }}
              hasMore={hasMoreData}
            />
          )}
        </>
      ) : (
        <SelectedStoreDetails
          // storeDetails={storeDetails}
          setShowStoreDetails={setShowStoreDetails}
        />
      )}
      {cart.length !== 0 && ShowAllStores !== true && (
        <SummaryOrderBtn
          setBasketDrawer={setBasketDrawer}
          getPaymentDetails={getPaymentDetails}
          basketDrawer={basketDrawer}
        />
      )}
      {checkHostModal && (
        <ComponentCheckHostModal
          showModal={checkHostModal}
          closeHanlder={() => setCheckHostModal(false)}
          setShowAllStores={setShowAllStores}
        />
      )}
      {showModal && (
        <ComponentProductDrawer
          showModal={showModal}
          handleClose={handleClose}
          product={selectedProduct}
          orderList={orderList}
        />
      )}
      <ComponentBasketDrawer
        basketDrawer={basketDrawer}
        handleClose={handleBasketDrawerClose}
        totalQueueToday={totalQueueToday}
        setUsePointsDrawer={setUsePointsDrawer}
        setApplyCardDrawer={setApplyCardDrawer}
        setApplyCouponDrawer={setApplyCouponDrawer}
        paymentDetailsHome={paymentDetails}
        getPaymentDetails={getPaymentDetails}
        setPaymentDetailsHome={setPaymentDetails}
        pointsInput={pointsInput}
        isApplyPromotionAuto={isApplyPromotionAuto}
        setIsApplyPromotionAuto={setIsApplyPromotionAuto}
        setPaymentDrawer={setPaymentDrawer}
        setPromoCode={setPromoCode}
        setPartnerPromoCode={setPartnerPromoCode}
        setPromotionIdByPromoCode={setPromotionIdByPromoCode}
        promoCode={promoCode}
        partnerPromoCode={partnerPromoCode}
        promotionIdByPromoCode={promotionIdByPromoCode}
        setSelectedFreebies={setSelectedFreebies}
        selectedFreebies={selectedFreebies}
        createProductOrderPOSByPaySolution={(ref: string) => createProductOrderPOSByPaySolution(ref)}
      />

      <ComponentUsePointDrawer
        usePointsDrawer={usePointsDrawer}
        handleClose={handleUsePointsClose}
        paymentDetails={paymentDetails}
        getPaymentDetails={getPaymentDetails}
        setPointsInput={setPointsInput}
        pointsInput={pointsInput}
      />
      {applyCardDrawer && (
        <ComponentApplyCardDrawer
          applyCardDrawer={applyCardDrawer}
          handleClose={handleApplyCardClose}
          paymentDetails={paymentDetails}
          getPaymentDetails={getPaymentDetails}
          setDiscountGiftCard={setDiscountGiftCard}
          setDiscountGiftCardPromotion={setDiscountGiftCardPromotion}
          setGiftCardPromotionId={setGiftCardPromotionId}
          setGiftCardDetail={setGiftCardDetail}
        />
      )}
      {applyCouponDrawer && (
        <ComponentApplyCouponDrawer
          applyCouponDrawer={applyCouponDrawer}
          handleClose={handleApplyCouponClose}
          setPromoCode={setPromoCode}
          promoCode={promoCode}
        />
      )}
      {paymentDrawer && (
        <ComponentPaymentDrawer
          paymentDrawer={paymentDrawer}
          handleClose={handlePaymentClose}
          paymentDetails={paymentDetails}
          getPaymentDetails={getPaymentDetails}
          setDiscountMemberCardPromotion={setDiscountMemberCardPromotion}
          setMemberCardPromotionId={setMemberCardPromotionId}
          setMemberCardDetail={setMemberCardDetail}
          createProductOrderPOS={(ref: string) => createProductOrderPOS(ref)}
        />
      )}
      {productSetAddonDrawer && (
        <ComponentProductSetAddonDrawer
          productSetAddonDrawer={productSetAddonDrawer}
          handleClose={handleProductSetAddonClose}
          productAddonList={selectProductSet}
        />
      )}
    </>
  );
};

const StyledSkeleton = styled.div`
  padding: 12px;
  .title-skeleton {
    position: relative;
    margin-bottom: 35px;
    padding-left: 30px;
    margin-top: 20px;
    &:after {
      content: "";
      position: absolute;
      width: 5px;
      border-radius: 5px;
      height: 30px;
      background-color: #e7e7e7;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .ant-skeleton {
      .ant-skeleton-title {
        height: 10px;
        border-radius: 16px;
        width: 120px !important;
        margin: 0;
      }
    }
  }
  .content-skeleton {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    .ant-skeleton {
      display: flex;
      width: 100%;
      flex-direction: column;
      .ant-skeleton-header {
        padding-right: 0;
        span {
          width: 100%;
          height: 167px;
          border-radius: 14px;
        }
      }
      .ant-skeleton-title {
        width: 100% !important;
        height: 10px;
        border-radius: 4px;
      }
      .ant-skeleton-paragraph {
        margin-top: 0 !important;
        li {
          height: 10px;
          border-radius: 4px;
          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
`;

const StyledIconPin = styled(IconPin)`
  cursor: pointer;
`;

const StyledIconCall = styled(IconCall)`
  cursor: pointer;
`;

const Styledarrow = styled(arrow)`
  cursor: pointer;
`;
